<template>
<div style="padding-right:35%">

    <img src="../../assets/images/logo.png" class="animate__animated animate__rubberBand" alt="" />

    <div class="card">
        <div>
            <h5 style="text-align: center;padding-left: 5%;">name</h5>
            <InputText class="field col-6 md:col-6" type="text" v-model="body.name" />
            <h5 style="text-align: center;padding-left: 5%;">title</h5>
            <InputText class="field col-6 md:col-6" type="text" v-model="body.title" />
            <h5 style="text-align: center;padding-left: 5%;">phone</h5>
            <InputText class="field col-6 md:col-6" type="text" v-model="body.phone" />
            <h5 style="text-align: center;padding-left: 7%;">message</h5>
            <textarea v-model="body.body" rows="5" cols="45" />
            </div>

<button @click="add()" class="button">{{$t('add')}}</button>
    </div>

</div>
</template>

<script>
export default {

    data() {
        return {

            body: {
                name: null,
                title: null,
                phone: null,
                body: null,
            }
        };
    },

    methods: {
        add() {
            this.body.name && this.body.title && this.body.body && this.body.phone
            if (!this.body.name) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: "please add name field",
                    life: 3000,
                });
                return
            }
            if (!this.body.title) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: "please add title field",
                    life: 3000,
                });
                return
            }
            if (!this.body.phone) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: "please add phone field",
                    life: 3000,
                });
                return
            }
            if (!this.body.body) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$t("Error"),
                    detail: "please add message field",
                    life: 3000,
                });
                return
            } else {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(this.body)
                };
                fetch("http://134.209.219.21:7000/api/connectus", requestOptions)
                    .then(response => response.json())
                    .then(
                (res) => {
                    this.body = {
                        name: null,
                        title: null,
                        body: null,
                    };
                    this.$swal({
                        title: this.$t("Successfully Done"),
                        icon: "success",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                },
                (err) => {
                    this.$toast.add({
                        severity: "error",
                        summary: this.$t("Error"),
                        detail: err.response.data.message,
                        life: 3000,
                    });
                }
            );
            }

            // this.$http.post("connectus", this.body).then(
            //     (res) => {
            //         this.body = {
            //             name: null,
            //             title: null,
            //             body: null,
            //         };
            //         this.$swal({
            //             title: this.$t("Successfully Done"),
            //             icon: "success",
            //             timer: 3000,
            //             showConfirmButton: false,
            //         });
            //     },
            //     (err) => {
            //         this.$toast.add({
            //             severity: "error",
            //             summary: this.$t("Error"),
            //             detail: err.response.data.message,
            //             life: 3000,
            //         });
            //     }
            // );

        }
    
},
async created() {

},
};
</script>

<style>
.button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 100px;
    cursor: pointer;
    width: 20%;
}
</style>
